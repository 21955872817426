import { Import } from './importModule';

const articleModules: Import = {
  selector: ['.bo-article-text'],
  importFn: [
    async () => {
      const {
        behaviorTracking,
        formatLinks,
        fixSafariParallax,
        parallaxTextCleanUp,
        addEmbedClass,
        articleTextIcon,
      } = await import('@ts/helpers/articleModule');

      behaviorTracking();
      formatLinks();
      parallaxTextCleanUp();
      fixSafariParallax();
      addEmbedClass();
      articleTextIcon();
    },
  ],
};

const blockedIframe: Import = {
  selector: ['#newsletter-iframe'],
  importFn: [
    async () => {
      const { blockedIframe } = await import(
        '@ts/helpers/iframe/blockedIframe'
      );
      blockedIframe('#newsletter-iframe', '#newsletter-iframe-fallback');
    },
  ],
};

const checkboxes: Import = {
  selector: ['.js-checkbox-main'],
  importFn: [
    async () => {
      const { setToggleCheckboxes } = await import(
        '@ts/helpers/toggle/toggleCheckboxes'
      );
      setToggleCheckboxes();
    },
  ],
};

const collapsible: Import = {
  selector: ['.js-collapsible'],
  importFn: [
    async () => {
      const { collapsible } = await import('@ts/helpers/toggle/collapsible');
      collapsible();
    },
  ],
};

const customTinymce: Import = {
  selector: ['textarea'],
  importFn: [
    async () => {
      const { customTinymce } = await import('@ts/helpers/tinymce/tinymce');
      customTinymce();
    },
  ],
};

const datawrapperResize: Import = {
  selector: [
    '.portaltype-freitag-article-article',
    '[id^="datawrapper-chart"]',
  ],
  importFn: [
    async () => {
      const { datawrapperResize } = await import(
        '@ts/helpers/articleEmbed/datawrapperResize'
      );
      datawrapperResize();
    },
  ],
};

const handleMatomoPing: Import = {
  selector: ['.js-matomo-ping'],
  importFn: [
    () =>
      import('@ts/helpers/analytics/pingMatomo').then(({ handleMatomoPing }) =>
        handleMatomoPing(),
      ),
  ],
};

const headerOnScroll: Import = {
  selector: ['.js-header'],
  importFn: [
    () =>
      import('@ts/helpers/header/headerOnScroll').then(({ headerOnScroll }) =>
        headerOnScroll(),
      ),
  ],
};

const instagramEmbed: Import = {
  selector: ['.instagram-media'],
  importFn: [
    async () => {
      const { instagramEmbed } = await import(
        '@ts/helpers/articleEmbed/instagramEmbed'
      );
      instagramEmbed();
    },
  ],
};

const involveMe: Import = {
  selector: ['.involveme_embed'],
  importFn: [
    async () => {
      const { involveMe } = await import('@ts/helpers/articleEmbed/involveMe');
      involveMe();
    },
  ],
};

const moveAuthors: Import = {
  selector: ['.js-move-author-from-derfreitag'],
  importFn: [
    async () => {
      const { moveAuthors } = await import('@ts/helpers/moveAuthors');
      moveAuthors();
    },
  ],
};

const offCanvas: Import = {
  selector: ['.js-offcanvas-toggle'],
  importFn: [
    async () => {
      const { offCanvas } = await import('@ts/helpers/header/offCanvas');
      offCanvas();
    },
  ],
};

const placeAboBreaker: Import = {
  selector: ['.bc-abo-breaker'],
  importFn: [
    async () => {
      const { placeAboBreaker } = await import(
        '@ts/helpers/banners/placeAboBreaker'
      );
      placeAboBreaker();
    },
  ],
};

const placeAsidesInArticle: Import = {
  selector: ['.js-reco-teaser'],
  importFn: [
    async () => {
      const { placeRecoTeaser } = await import(
        '@ts/helpers/recoTeaser/placeRecoTeaser'
      );

      // Only call for reco teaser pleacement early on if there are no images on the article
      if (!document.querySelector<HTMLElement>('.pts-article-image')) {
        placeRecoTeaser();
      }
    },
  ],
};

const quizzes: Import = {
  selector: ['A[quiz],DIV[quiz],A[data-quiz],DIV[data-quiz]'],
  importFn: [
    async () => {
      const { quizzes } = await import('@ts/helpers/articleEmbed/quizzes');
      quizzes();
    },
  ],
};

const recoTeaser: Import = {
  selector: ['.js-reco-teaser-nav-item'],
  importFn: [
    async () => {
      const { recoTeaser } = await import('@ts/helpers/recoTeaser/recoTeaser');
      recoTeaser();
    },
  ],
};

const relatedArticles: Import = {
  selector: ['.js-add-related-articles'],
  importFn: [
    async () => {
      const { setRelatedArticles } = await import(
        '@ts/helpers/relatedArticles/relatedArticles'
      );
      setRelatedArticles();
    },
  ],
};

const secretLinksDialog: Import = {
  selector: ['.js-open-dialog'],
  importFn: [
    async () => {
      const { secretLinksDialog } = await import(
        '@ts/helpers/secretLinks/secretLinksDialog'
      );
      secretLinksDialog();
    },
  ],
};

const scrollXMask: Import = {
  selector: ['.js-scroll-x-mask'],
  importFn: [
    async () => {
      const { scrollXMask } = await import('@ts/helpers/scroll/scrollXMask');
      scrollXMask();
    },
  ],
};

const showMoreClusterItems: Import = {
  selector: ['.js-show-more-cluster-items'],
  importFn: [
    async () => {
      const { showMoreClusterItems } = await import(
        '@ts/helpers/showMoreClusterItems'
      );
      showMoreClusterItems();
    },
  ],
};

const stickyBanner: Import = {
  selector: ['.js-abo-banner'],
  importFn: [
    async () => {
      const { stickyBanner } = await import('@ts/helpers/banners/stickyBanner');
      stickyBanner();
    },
  ],
};

const toggleCaption: Import = {
  selector: ['.js-toggle-caption'],
  importFn: [
    async () => {
      const { setToggleCaption } = await import(
        '@ts/helpers/toggle/toggleCaption'
      );
      setToggleCaption();
    },
  ],
};

const toggleDialog: Import = {
  selector: ['.js-open-dialog'],
  importFn: [
    async () => {
      const { toggleDialog } = await import('@ts/helpers/toggle/toggleDialog');
      toggleDialog('.js-open-dialog');
    },
  ],
};

const twitterEmbed: Import = {
  selector: ['.instagram-media'],
  importFn: [
    async () => {
      const { twitterEmbed } = await import(
        '@ts/helpers/articleEmbed/twitterEmbed'
      );
      twitterEmbed();
    },
  ],
};

const unmarkAuthors: Import = {
  selector: ['.js-unmark-author'],
  importFn: [
    async () => {
      const { unmarkAuthors } = await import('@ts/helpers/unmarkAuthors');
      unmarkAuthors();
    },
  ],
};

export const helperModules = [
  articleModules,
  blockedIframe,
  checkboxes,
  collapsible,
  customTinymce,
  datawrapperResize,
  handleMatomoPing,
  headerOnScroll,
  instagramEmbed,
  involveMe,
  moveAuthors,
  offCanvas,
  placeAboBreaker,
  placeAsidesInArticle,
  quizzes,
  recoTeaser,
  relatedArticles,
  secretLinksDialog,
  scrollXMask,
  showMoreClusterItems,
  stickyBanner,
  toggleCaption,
  toggleDialog,
  twitterEmbed,
  unmarkAuthors,
];
